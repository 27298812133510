import React from 'react';
import { ReactComponent as OrientationIcon } from '../../icons/orientation.svg';
import {Helmet} from "react-helmet";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import MenuComponent from "../../menu/menu";
import { useTranslation } from "react-i18next";
import WhyComponent from './why/why';
import IQ from '../../images/IQ.png';
import temple from '../../images/temple.png';
import advantages from '../../images/advantages.png';
import enterprises from '../../images/enterprises.png';
import governmental from '../../images/governmental.png';
import aceros from '../../images/collaborators/aceros.png';
import cambio from '../../images/collaborators/cambio.png';
import cerraduras from '../../images/collaborators/cerraduras.png';
import ibarra from '../../images/collaborators/ibarra.png';
import proenergia from '../../images/collaborators/proenergia.png';
import psocus from '../../images/collaborators/psocus.png';
import ventura from '../../images/collaborators/ventura.png';
import "../../i18n/i18n";
import './enterprises.css';
import FormComponent from './form/form';
import { Link } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';


const EnterprisesComponent = () => {
    const { t } = useTranslation();
    const collaborators = [
        {
            image: ventura
        },
        {
            image: aceros
        },
        {
            image: proenergia
        },
        {
            image: ibarra
        },
        {
            image: psocus
        },
        {
            image: cambio
        },
        {
            image: cerraduras
        },
    ];
    return (
        <div>
            <Helmet>
                <title>{t("menu_enterprises")} - {t("iqmedik")}</title>
                <meta name="description" content={t("enterprises_form_subtitle")} />
                <link rel="canonical" href="https://iqmedik.com/enterprises"></link>
            </Helmet>
            <MenuComponent />
            <div className="iq__enterprises">
                <div className="iq__enterprises__init">
                    <Typography variant="h2">{t("enterprises_title")}</Typography>
                    <Typography variant="subtitle1">{t("enterprises_subtitle")}</Typography>
                    <img className="iq__enterprises__init__img" src={enterprises} alt="doctor"/>
                    <Button className="iq__enterprises__init__button" variant="contained" color="primary" onClick={event => scroll.scrollToBottom()}>
                        {t("button_contact")}
                    </Button>
                    <Link to="/about" className="iq__enterprises__init__call">
                        <Typography>{t("home_how")}</Typography>
                        <OrientationIcon/>
                    </Link>
                </div>
                <WhyComponent/>
                <div className="iq__enterprises__governmental">
                    <div className="iq__enterprises__governmental__content">
                        <div className="iq__enterprises__governmental__black">
                            <Typography variant="h2">{t("governmental_title")}</Typography>
                        </div>
                        <div className="iq__enterprises__governmental__green">
                            <img src={temple} alt={t("governmental_title")}/>
                        </div>
                    </div>
                    <img className="iq__enterprises__governmental__image" src={governmental} alt={t("governmental_title")}/>
                    <div className="iq__enterprises__governmental__black">
                        <div>
                            <Typography variant="h3">1</Typography>
                            <Typography variant="h3">{t("regulation_one")}</Typography>
                        </div>
                        <div>
                            <Typography variant="h3">2</Typography>
                            <Typography variant="h3">{t("regulation_two")}</Typography>
                        </div>
                    </div>
                </div>
                <div className="iq__enterprises__advantages">
                    <div>
                        <Typography variant="h3">{t("advantages_title")}</Typography>
                        <Typography variant="subtitle1">{t("advantages_agility")}</Typography>
                        <ul>
                            <li><Typography variant="subtitle2">{t("advantages_agility_description")}</Typography></li>
                        </ul>
                        <Typography variant="subtitle1">{t("advantages_security")}</Typography>
                        <ul>
                            <li><Typography variant="subtitle2">{t("advantages_security_description")}</Typography></li>
                        </ul>
                        <Typography variant="subtitle1">{t("advantages_staff")}</Typography>
                        <ul>
                            <li><Typography variant="subtitle2">{t("advantages_staff_description")}</Typography></li>
                        </ul>
                    </div>
                    <img src={advantages} alt={t("advantages_title")}/>
                </div>
                <div className="iq__enterprises__benefits">
                    <Typography variant="h3">{t("benefits_title")}</Typography>
                    <Typography variant="subtitle1">{t("benefits_subtitle")}</Typography>
                    <div className="iq__enterprises__benefits__content">
                        <ul>
                            <li><Typography>{t("benefits_one")}</Typography></li>
                            <li><Typography>{t("benefits_two")}</Typography></li>
                            <li><Typography>{t("benefits_three")}</Typography></li>
                            <li><Typography>{t("benefits_four")}</Typography></li>
                            <li><Typography>{t("benefits_five")}</Typography></li>
                        </ul>
                        <img src={IQ} alt={t("benefits_title")}/>
                    </div>
                </div>
                <div className="iq__enterprises__collaborators">
                    <Typography variant="h3">{t("collaborators_title")}</Typography>
                    <div className="iq__enterprises__collaborators__container">
                        {collaborators.map((item, index) =>
                            <img className="iq__enterprises__collaborators__card" src={item.image} alt={t(item.text)} key={index}/>
                        )}
                    </div>
                </div>
                <FormComponent />
            </div>
        </div>
    )
}

export default EnterprisesComponent;