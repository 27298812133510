import React from "react";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { GoogleReCaptcha, withGoogleReCaptcha } from "react-google-recaptcha-v3";
import { withTranslation } from 'react-i18next';
import { ReactComponent as NameIcon } from "../../../icons/name.svg";
import { ReactComponent as EmailIcon } from "../../../icons/email.svg";
import InputAdornment from "@material-ui/core/InputAdornment";
import "../../../i18n/i18n";
import "./form.css";

class FormComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      form: {
        name: "",
        type: "enterprise",
        email: "",
        recaptchaToken: "",
      },
      errors: {},
      isLoadingForm: false,
      isVerifyRecaptcha: false
    };
    this.setToken = this.setToken.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
  }

  handleChangeName(event) {
    let form = { ...this.state.form, name: event.target.value }
    let errors = this.state.errors;
    delete errors["name"]
    this.setState({ form: form, errors: errors });
  }

  handleChangeEmail(event) {
    let form = { ...this.state.form, email: event.target.value }
    let errors = this.state.errors;
    delete errors["email"]
    this.setState({ form: form, errors: errors });
  }

  setToken(event) {
    if(event) {
      this.setState({isVerifyRecaptcha: true})
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    let errors = {};
    if(this.state.form.name === '') {
      errors["name"] = "error_name";
    }
    if(this.state.form.email === '' || !this.state.form.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      errors["email"] = "error_email";
    }
    this.setState({errors: errors});
    if(Object.keys(errors).length === 0 && !this.state.isLoadingForm) {
      this.setState({isLoadingForm: true});
      let { form } = this.state
      this.props.googleReCaptchaProps.executeRecaptcha('enterprises').then((response) => {
        form.recaptchaToken = response;
        fetch('https://api.iqmedik.com/v1/web/contactform', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(form)
        })
        .then(() => {
          this.setState({ 
            form: {
              name: "",
              type: "enterprise",
              email: "",
              recaptchaToken: "",
            },
            errors: {},
            isLoadingForm: false
          })
        });
      })
    }
  }

  render() {
    const { t } = this.props;
    return (
      <div className="iq__form">
        <div className="iq__form__text">
          <Typography variant="h3">
            {t("enterprises_form_title1")}
            <span>{t("enterprises_form_title_green")}</span>
            {t("enterprises_form_title2")}
          </Typography>
          <Typography className="iq__form__subtitle">
            {t("enterprises_form_subtitle")}
          </Typography>
        </div>
        <form onSubmit={this.handleSubmit}>
          <Typography>{t("enterprises_form_subtitle_two")}</Typography>
          <Input
            value={this.state.form.name}
            onChange={this.handleChangeName}
            placeholder={t("placehorder_name")}
            startAdornment={
              <InputAdornment position="start">
                <NameIcon />
              </InputAdornment>
            }
          />
          <span className="iq__form__error">{t(this.state.errors["name"])}</span>
          <Input
            type="email"
            value={this.state.form.email}
            onChange={this.handleChangeEmail}
            placeholder={t("placehorder_email")}
            startAdornment={
              <InputAdornment position="start">
                <EmailIcon />
              </InputAdornment>
            }
          />
          <span className="iq__form__error">{t(this.state.errors["email"])}</span>
          <GoogleReCaptcha
            onVerify={this.setToken}
            reset={true}
          />
          <Button
            className="iq__about__init__button"
            disabled={this.state.isLoadingForm}
            variant="contained"
            color="primary"
            type="submit"
          >
            {t("button_send")}
          </Button>
        </form>
      </div>
    );
  }
}

export default withGoogleReCaptcha(withTranslation()(FormComponent));
