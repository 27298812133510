import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from "react-i18next";
import clock from '../../../icons/clock.svg';
import person from '../../../icons/person.svg';
import pin from '../../../icons/pin.svg';
import report from '../../../icons/report.svg';
import certification from '../../../icons/certification.svg';
import heart from '../../../icons/heart.svg';
import chart from '../../../icons/chart.svg';
import man from '../../../icons/man.svg';
import price from '../../../icons/price.svg';
import "../../../i18n/i18n";
import './why.css';


const WhyComponent = () => {
    const { t } = useTranslation();
    const reasons = [
        {
            image: clock,
            text: 'reason_one'
        },
        {
            image: report,
            text: 'reason_two'
        },
        {
            image: price,
            text: 'reason_three'
        },
        {
            image: person,
            text: 'reason_four'
        },
        {
            image: certification,
            text: 'reason_five'
        },
        {
            image: chart,
            text: 'reason_six'
        },
        {
            image: pin,
            text: 'reason_seven'
        },
        {
            image: heart,
            text: 'reason_eight'
        },
        {
            image: man,
            text: 'reason_nine'
        },
    ];
    return (
        <div className="iq__why">
            <Typography variant="h3">
                {t("why_subtitle1")}
                <span>
                    {t("why_subtitle_bold")}
                </span>
                {t("why_subtitle2")}
            </Typography>
            <div className="iq__why__reasons">
                <Typography variant="h2">{t("why_title")}</Typography>
                <div className="iq__why__reasons__container">
                    {reasons.map((item, index) => 
                        <div className="iq__why__reason" key={index}>
                            <img src={item.image} alt={t(item.text)}/>
                            <Typography variant="subtitle1">{t(item.text)}</Typography>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default WhyComponent;